@font-face {
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
font-family: 'latoFont';
src: url(/_next/static/media/b7ffde2383bb16ba-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
font-family: 'latoFont';
src: url(/_next/static/media/0ed72b868859dbf2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
font-family: 'latoFont';
src: url(/_next/static/media/d5eb20bcdcf5616a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
font-family: 'latoFont';
src: url(/_next/static/media/9c868634695237b9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: 'latoFont Fallback';src: local("Arial");ascent-override: 100.88%;descent-override: 21.77%;line-gap-override: 0.00%;size-adjust: 97.84%
}.__className_5597f7 {font-family: 'latoFont', 'latoFont Fallback'
}

